<template>
  <div>
    <Header />
    <div class="app-body">
      <Navbar />
      <div class="router-view-box">
        <Breadcrumbs />
        <router-view class="router-view" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Navbar from "@/components/Navbar.vue";
import Breadcrumbs from "@/components/Breadcrumb.vue";

export default {
  name: "Layout",
  components: {
    Header,
    Navbar,
    Breadcrumbs,
  },
};
</script>

<style lang="scss" scoped>
.app-body {
  display: grid;
  grid-template-columns: 270px minmax(0, auto);
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: 57px;
}

.router-view-box {
  padding: 30px;
}
.router-view {
  padding-top: 30px;
  box-sizing: border-box;
}
</style>
