<template>
  <div id="nav">
    <ul class="list-unstyled ps-0">
      <li><router-link to="/">Главная</router-link></li>
      <li>
        <a
          class="collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#plan-collapse"
          aria-expanded="true"
          >План</a
        >
        <div class="collapse show" id="plan-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 ps-2 small">
            <li>
              <router-link to="/overview">План работ</router-link>
            </li>
            <li>
              <a href="#">План закупок</a>
            </li>
          </ul>
        </div>
      </li>
      <li class="mb-1">
        <a
          class="collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded="true"
          >Справочник</a
        >
        <div class="collapse show" id="home-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 ps-2 small">
            <li>
              <a
                class="collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#tech-maps"
                aria-expanded="true"
                >Нормативы</a
              >
              <div class="collapse" id="tech-maps">
                <ul
                  class="btn-toggle-nav list-unstyled fw-normal pb-1 ps-2 small"
                >
                  <li>
                    <router-link to="/references/labor-code"
                      >Часовые тарифные ставки труда</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/references/qualification-requirement"
                      >Квалификационные требования</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/references/work-equipment"
                      >Часовые стоимости эксплуатации оборудования</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/references/type-material"
                      >Типы материалов</router-link
                    >
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a
                class="collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#tech-maps-toir"
                aria-expanded="true"
                >Технологические карты ТОиР</a
              >
              <div class="collapse" id="tech-maps-toir">
                <ul
                  class="btn-toggle-nav list-unstyled fw-normal pb-1 ps-2 small"
                >
                  <li>
                    <router-link to="/references/technological-maps"
                      >Группы технологических карт</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/references/technological-maps/condition-factors"
                      >Поправочные коэффициенты</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/references/technological-maps/cFGroup"
                      >Группы поправочных коэффициентов</router-link
                    >
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <router-link to="/references/kinds">Виды</router-link>
            </li>
            <li>
              <router-link to="/references/import">Импорт данных</router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style lang="scss">
#nav {
  background: $main-3;

  a {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 30px;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 2px solid $main-1;
    text-decoration: none;
    color: $text-second;
    cursor: pointer;

    &:hover {
      color: $text-main;
      border-color: $main-1;
    }
    &.router-link-exact-active {
      color: $text-highlight;
    }
  }
}
</style>
