<template>
  <div id="breadcrumb">
    Реж / <router-link to="/">ТОиР</router-link>

    <template v-for="crumb in crumbs">
      /
      <router-link v-if="crumb.link" :key="crumb.link" :to="crumb.link">
        {{ crumb.text }}
      </router-link>
      <span v-else :key="crumb.text"> {{ crumb.text }}</span>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    crumbs: function () {
      if (this.$route.meta && this.$route.meta.breadCrumb)
        return this.$route.meta.breadCrumb(this.$route);
      else return [{ text: this.$route.name }];
    },
  },
};
</script>

<style lang="scss">
#breadcrumb {
  color: $text-second;
  font-weight: bold;
  cursor: default;

  a {
    color: $text-main;
    text-decoration: none;

    &:hover {
      color: $text-highlight;
    }
  }
}
</style>
