<template>
  <header>
    <img src="@/assets/logo-white.png" alt="ENPRO Logo" />
    <div class="d-inline-flex align-items-center gap-2">
      <i class="bi bi-person-circle"></i>
      {{ userName }}
      <UIButton
        class="ms-3"
        left-icon="bi-box-arrow-right"
        text="Выход"
        float
        @click="logout"
      />
    </div>
  </header>
</template>

<script>
import UIButton from "@/components/UI/Button";

export default {
  components: { UIButton },
  computed: {
    userName() {
      if (this.$store.getters.isUserLoaded) {
        const _d = this.$store.getters.userData;
        if (_d.lastName || _d.firstName || _d.mName) {
          let _s = [];
          if (_d.lastName) _s.push(_d.lastName);
          if (_d.firstName) _s.push(_d.firstName);
          if (_d.mName) _s.push(_d.mName);
          return _s.join(" ");
        }
        return `Id пользователя: ${_d.id}`;
      }
      return "Загрузка";
    },
  },
  methods: {
    logout() {
      this.$store.commit("auth_error");
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss">
header {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  padding: 0 30px;
  background-color: $main-1;
}

header img {
  position: relative;
  top: 3px;
  width: 124px;
}
</style>
